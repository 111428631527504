const menuPaths = {
  RECHARGE: "recharge",
  HISTORY: "historico",
  TIMELINE: "timeline",
  CARDS: "cards",
  BALANCE: "saldo",
  HOME: "home",
  VALOR: "numero",
  PAGAMENTO: "pagamento",
  CONFIRMACAO: "confirmacao",
  NUMERO: "numero",
  MANAGE_SCHEDULE_RECHARGE: "gerenciar-programadas",
  SUCCESS_PAYMENT: "pagamento-sucesso",
  ERROR_PAYMENT: "pagamento-erro",
  CREATE_CARD: "criar-cartao",
  CUSTOMER_DATA: "meus-dados",
  CONFIRMACAO_PIX: "confirmacao-pix",
};

export default menuPaths;
