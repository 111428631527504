export const eventNames = {
  confirmation: "confirmation",
};

export const paymentMethod = {
  credit: "CREDIT_CARD",
  pix: "PIX",
};

export default eventNames;
