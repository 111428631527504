import { call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";

import {
  doSaveRecharges,
  doRechargeSuccess,
  doRechargeError,
  doSelectCreditCard,
  doChangeNewRecharge,
} from "../actions/recharge";
import { doSaveExpressRecharge } from "../actions/expressRecharge";
import { doShowLoading, doHideLoading, doShowError } from "../actions/ui";
import {
  requestNewRecharge,
  fetchRecharges,
  requestNewPixEncryptedRecharge,
} from "../api/recharge";
import { requestTokenization } from "../api/paymentMethod";
import paymentMethodsEnum from "../enum/paymentMethods";
import { getFingerprint } from "../selectors/fraudAnalysis";
import { getPaymentMethod } from "../selectors/recharge";
import { knownErrors } from "../enum/knownErrors";
import { doShowOTPModal, doUnvalidateSMSToken, doResetState } from "../actions/otpModal";
import { isFeatureEnabled } from "../selectors/feature";
import {
  clearOfferId,
  clearSessionId,
  clearTid,
  getChannel,
  getOfferId,
  getPixExpiration,
  getRouteForPaymentMethod,
  getSessionId,
  getTid,
  isElegibleTagsChannel,
} from "../lib/utils";
import rechargeErrors from "../errors/rechargeErrors";
import { mountEventPayload } from "../selectors/events";
import eventNames from "../enum/events";

function* handleRequestNewRecharge(action) {
  yield put(doShowLoading());
  const tid = getTid();
  const sessionId = getSessionId();
  const offerId = getOfferId();

  try {
    const { targetMsisdn, paymentMethod, frequency, rechargeValue } = action.payload;
    const updatedPayload = { ...action.payload };

    const fingerprint = yield select(getFingerprint);

    const channel = getChannel();

    if (isElegibleTagsChannel(channel)) {
      updatedPayload.tags = mountEventPayload(
        eventNames.confirmation,
        updatedPayload,
        tid,
        sessionId,
        offerId
      );
    }

    const result = yield call(requestNewRecharge, updatedPayload, fingerprint);

    if (paymentMethod.type === paymentMethodsEnum.MERCADO_PAGO) {
      if (result && result.data && result.data.authentication_url) {
        window.location.href = result.data.authentication_url;
      }
    } else if (paymentMethod.type === paymentMethodsEnum.CREDITO) {
      yield put(
        doSaveExpressRecharge({
          targetMsisdn,
          rechargeValue: rechargeValue.value,
          token: paymentMethod.data.token,
          cvv: null,
          productId: rechargeValue.id,
          day: null,
          frequency,
          brandName: paymentMethod.data.brandName,
          lastDigits: paymentMethod.data.lastDigits,
          type: paymentMethodsEnum.CREDITO,
        })
      );
    } else if (paymentMethod.type === paymentMethodsEnum.PIX) {
      const paymentMethodPix = {
        data: {
          qrCode: result.data.qrCode,
          transactionId: result.data.transactionId,
          expiration: getPixExpiration(),
        },
        type: paymentMethodsEnum.PIX,
      };

      yield put(doChangeNewRecharge("paymentMethod", paymentMethodPix));
    }

    const selectedPaymentSource = yield select(getPaymentMethod);

    yield put(
      doRechargeSuccess(paymentMethod, { msisdn: targetMsisdn }, selectedPaymentSource.data, {
        ...result.data,
        value: rechargeValue.value,
      })
    );

    const paymentType = paymentMethod.type;
    const route = getRouteForPaymentMethod(paymentType);

    if (tid) clearTid();
    if (sessionId) clearSessionId();
    if (offerId) clearOfferId();
    yield put(push(route));
  } catch (e) {
    const otpModal = yield select(state => isFeatureEnabled(state, "OTPModal"));

    if (otpModal && e.response.status === 423) {
      yield put(doShowOTPModal());
      yield put(doUnvalidateSMSToken());
    }

    if (e.response.status === 400 && e.response.data.message === rechargeErrors[400].message) {
      yield put(doRechargeError());
      yield put(doShowError(rechargeErrors[400].handlingMessage));
      yield put(push("numero"));
    } else if (e.response.status === 504) {
      yield put(push("confirmacao"));
    } else {
      yield put(doRechargeError());
      if (action.payload.paymentMethod.type === paymentMethodsEnum.CREDITO) {
        yield put(push("pagamento-erro"));
      } else yield put(doShowError("Erro ao efetuar a recarga.", e));
    }
  } finally {
    yield put(doResetState());
    yield put(doHideLoading());
  }
}

function* handleRequestNewNoCreditRecharge(action) {
  yield put(doShowLoading());

  const { newRecharge } = action.payload;

  let paymentMethodData = null;
  let authentication = null;
  let type = null;

  if (action.isCardTokenized) {
    paymentMethodData = {
      token: newRecharge.paymentMethod.data.token,
      lastDigits: newRecharge.paymentMethod.data.lastDigits,
      brandName: newRecharge.paymentMethod.data.brandName,
      cvv: action.payload.cvv,
    };

    // eslint-disable-next-line prefer-destructuring
    type = newRecharge.paymentMethod.type;
  } else {
    try {
      // eslint-disable-next-line prefer-destructuring
      type = action.payload.type;
      const { pan, month, year, cvv, brand, key, lastDigits, data3ds } = action.payload;
      const brandName = brand.replace(/^\w/, c => c.toUpperCase());

      if (!key) {
        const result = yield call(requestTokenization, pan, month, year);
        const { data } = result;
        if (!data.result) {
          throw new Error("No data result");
        } else if (!data.result.resultSuccess) {
          throw new Error(data.result.resultMessage);
        }

        yield put(doSelectCreditCard(0, data.card.key, data.card.last, data.card.brand));

        paymentMethodData = {
          token: data.card.key,
          lastDigits: data.card.last,
          brandName,
          cvv,
        };
      } else {
        paymentMethodData = {
          token: key,
          lastDigits,
          brandName,
          cvv,
        };
        authentication = data3ds;
      }
    } catch (e) {
      yield put(doShowError("Cartão inválido. Por favor, revise os dados do cartão.", e));
    } finally {
      yield put(doHideLoading());
    }
  }

  if (paymentMethodData) {
    try {
      const payload = {
        ...newRecharge,
        paymentMethod: {
          data: paymentMethodData,
          type,
          authentication,
        },
      };

      yield put(doChangeNewRecharge("paymentMethod", payload.paymentMethod));
      yield call(requestNewRecharge, payload);
      yield put(doRechargeSuccess(payload.paymentMethod.type));
      yield put(push("confirmacao"));
    } catch (e) {
      yield put(doRechargeError());
      yield put(doShowError("Erro ao efetuar a recarga.", e));
    } finally {
      yield put(doHideLoading());
    }
  }
}

function* handleFetchRecharges() {
  yield put(doShowLoading());

  try {
    const { data } = yield call(fetchRecharges);
    yield put(doSaveRecharges(data));
  } catch (e) {
    yield put(doShowError("Erro ao carregar as recargas.", e));
  } finally {
    yield put(doHideLoading());
  }
}

function* handleRequestNewPixEncryptedRecharge(action) {
  try {
    const { data } = action.payload;
    const result = yield call(requestNewPixEncryptedRecharge, data);

    const paymentMethodPix = {
      data: { transactionId: result.data.id },
      type: paymentMethodsEnum.PIX,
    };

    yield put(doChangeNewRecharge("paymentMethod", paymentMethodPix));
  } catch (error) {
    const errorMessage = knownErrors[(error.response?.data?.code)] || "Erro ao efetuar recarga";
    yield put(doShowError(errorMessage, error));
  } finally {
    yield put(doHideLoading());
  }
}

export {
  handleRequestNewRecharge,
  handleFetchRecharges,
  handleRequestNewNoCreditRecharge,
  handleRequestNewPixEncryptedRecharge,
};
