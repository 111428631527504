import { eventNames, paymentMethod } from "../enum/events";

const getConfirmationTagsPayload = (payload, tid, sessionId, offerId) => {
  let tags = {
    value: `${payload.rechargeValue.value / 100}`,
    currency: "BRL",
    inventory_type: "SIM_TOP_UP_WITH_DATA_BONUS",
    payment_method: paymentMethod[payload.paymentMethod.type],
    card_brand: payload.paymentMethod.data.brandName,
  };

  if (tid) {
    tags = { ...tags, transaction_id_meta: tid };
  }

  if (sessionId) {
    tags = { ...tags, session_id: sessionId };
  }

  if (offerId) {
    tags = { ...tags, offer_id: offerId };
  }

  return tags;
};

const mountEventPayload = (typeEvent, payload, tid, sessionId, offerId) => {
  let eventPayload = {};
  if (typeEvent === eventNames.confirmation) {
    eventPayload = getConfirmationTagsPayload(payload, tid, sessionId, offerId);
  }
  return eventPayload;
};

export { mountEventPayload };
